export const defaultJsonEditorStyle: React.CSSProperties = {
    padding: 20,
    minHeight: "40vh",
    maxHeight: "40vh",
    overflowY: "auto",
    borderRadius: 3,
    width: "50%",
};

export const expandedJsonEditorStyle: React.CSSProperties = {
    padding: 20,
    minHeight: "40vh",
    borderRadius: 3,
    width: "50%",
};

export const defaultLoaderWrapperStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50vh",
};
