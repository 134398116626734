export enum LocalStorageKeys {
    AUTH = "fobi-auth",
    GRAPESJS_PROJECT = "gjsProject",
    SELECTED_LICENSE_ID = "fobi-auth-user-selected-license-id",
    USER_LAST_LOGIN_DATE = "fobi-user-last-login-date",
    USER_COOKIE_CONSENT_ACCEPTED = "fobi-user-cookie-consent-accepted",
    KEYCLOAK_LOGIN_REDIRECT_IN_PROGRESS = "fobi-keycloak-login-redirect-in-progress",
    MFA_PROMPT = 'mfaPrompt',
    MFA_DECLINE = 'mfaDecline',
    LICENSE_REGISTRATION = "license-registration",
    LICENSE_REGISTRATION_COMPANY_DETAILS = "license-registration-company-details",
    LICENSE_REGISTRATION_BILLING_DETAILS = "license-registration-billing-details",
    LICENSE_REGISTRATION_BILLING_CHECKBOX = "license-registration-billing-checkbox",
    LICENSE_REGISTRATION_BRANDING_DETAILS = "license-registration-branding-details",
    SHALLOW_LICENSE_IDS = "shallow-license-ids",
}

export enum verificationType {
    SIGN_UP = "sign_up",
    FORGET_PASSWORD = "forgot_password",
    Update = "update"
}
