import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/index.scss";

import "./assets/scss/fonts/Lato.scss";
import "./assets/scss/fonts/Poppins.scss";
import "./assets/scss/fonts/Nunito.scss";
import "./assets/scss/fonts/Raleway.scss";
import "./assets/scss/fonts/Tourney.scss";
import "./assets/scss/fonts/Zeyada.scss";

const App = lazy(() => import("./App"));
import reportWebVitals from "./reportWebVitals";
import { AppSettingsProvider } from "./context";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <AppSettingsProvider>
        <App />
    </AppSettingsProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
