export const globals = {
    settings: {
        authUrl: "",
        authRealm: "",
        authClientId: "",
        serverUrl: "",
        cmsUrl: "",
        cmsGraphQlUrl: "",
        ciUrl: "",
    },
};
