/* eslint-disable no-use-before-define */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
        [P in keyof T]?: P extends " $fragmentName" | "__typename"
        ? T[P]
        : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404]
     * (http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
}

export interface Template {
    __typename?: "Template";
    id: Scalars["ID"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    license?: Maybe<Scalars["String"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    pages?: Maybe<Array<Page>>;
    pagesCount?: Maybe<Scalars["Int"]["output"]>;
    content?: Maybe<Scalars["JSON"]["output"]>;
}

export interface TemplatePagesArgs {
    where?: PageWhereInput;
    orderBy?: Array<PageOrderByInput>;
    take?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: Scalars["Int"]["input"];
    cursor?: InputMaybe<PageWhereUniqueInput>;
}

export interface TemplatePagesCountArgs {
    where?: PageWhereInput;
}

export interface TemplateWhereUniqueInput {
    id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface TemplateWhereInput {
    AND?: InputMaybe<Array<TemplateWhereInput>>;
    OR?: InputMaybe<Array<TemplateWhereInput>>;
    NOT?: InputMaybe<Array<TemplateWhereInput>>;
    id?: InputMaybe<IdFilter>;
    createdBy?: InputMaybe<StringFilter>;
    updatedBy?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeNullableFilter>;
    updatedAt?: InputMaybe<DateTimeNullableFilter>;
    license?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    description?: InputMaybe<StringFilter>;
    pages?: InputMaybe<PageManyRelationFilter>;
}

export interface IdFilter {
    equals?: InputMaybe<Scalars["ID"]["input"]>;
    in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    notIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    lt?: InputMaybe<Scalars["ID"]["input"]>;
    lte?: InputMaybe<Scalars["ID"]["input"]>;
    gt?: InputMaybe<Scalars["ID"]["input"]>;
    gte?: InputMaybe<Scalars["ID"]["input"]>;
    not?: InputMaybe<IdFilter>;
}

export interface StringFilter {
    equals?: InputMaybe<Scalars["String"]["input"]>;
    in?: InputMaybe<Array<Scalars["String"]["input"]>>;
    notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
    lt?: InputMaybe<Scalars["String"]["input"]>;
    lte?: InputMaybe<Scalars["String"]["input"]>;
    gt?: InputMaybe<Scalars["String"]["input"]>;
    gte?: InputMaybe<Scalars["String"]["input"]>;
    contains?: InputMaybe<Scalars["String"]["input"]>;
    startsWith?: InputMaybe<Scalars["String"]["input"]>;
    endsWith?: InputMaybe<Scalars["String"]["input"]>;
    mode?: InputMaybe<QueryMode>;
    not?: InputMaybe<NestedStringFilter>;
}

export enum QueryMode {
    Default = "default",
    Insensitive = "insensitive",
}

export interface NestedStringFilter {
    equals?: InputMaybe<Scalars["String"]["input"]>;
    in?: InputMaybe<Array<Scalars["String"]["input"]>>;
    notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
    lt?: InputMaybe<Scalars["String"]["input"]>;
    lte?: InputMaybe<Scalars["String"]["input"]>;
    gt?: InputMaybe<Scalars["String"]["input"]>;
    gte?: InputMaybe<Scalars["String"]["input"]>;
    contains?: InputMaybe<Scalars["String"]["input"]>;
    startsWith?: InputMaybe<Scalars["String"]["input"]>;
    endsWith?: InputMaybe<Scalars["String"]["input"]>;
    not?: InputMaybe<NestedStringFilter>;
}

export interface DateTimeNullableFilter {
    equals?: InputMaybe<Scalars["DateTime"]["input"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
    lt?: InputMaybe<Scalars["DateTime"]["input"]>;
    lte?: InputMaybe<Scalars["DateTime"]["input"]>;
    gt?: InputMaybe<Scalars["DateTime"]["input"]>;
    gte?: InputMaybe<Scalars["DateTime"]["input"]>;
    not?: InputMaybe<DateTimeNullableFilter>;
}

export interface PageManyRelationFilter {
    every?: InputMaybe<PageWhereInput>;
    some?: InputMaybe<PageWhereInput>;
    none?: InputMaybe<PageWhereInput>;
}

export interface TemplateOrderByInput {
    id?: InputMaybe<OrderDirection>;
    createdBy?: InputMaybe<OrderDirection>;
    updatedBy?: InputMaybe<OrderDirection>;
    createdAt?: InputMaybe<OrderDirection>;
    updatedAt?: InputMaybe<OrderDirection>;
    license?: InputMaybe<OrderDirection>;
    name?: InputMaybe<OrderDirection>;
    description?: InputMaybe<OrderDirection>;
}

export enum OrderDirection {
    Asc = "asc",
    Desc = "desc",
}

export interface TemplateUpdateInput {
    name?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    pages?: InputMaybe<PageRelateToManyForUpdateInput>;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
}

export interface PageRelateToManyForUpdateInput {
    disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
    set?: InputMaybe<Array<PageWhereUniqueInput>>;
    create?: InputMaybe<Array<PageCreateInput>>;
    connect?: InputMaybe<Array<PageWhereUniqueInput>>;
}

export interface TemplateUpdateArgs {
    where: TemplateWhereUniqueInput;
    data: TemplateUpdateInput;
}

export interface TemplateCreateInput {
    name?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    pages?: InputMaybe<PageRelateToManyForCreateInput>;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
}

export interface PageRelateToManyForCreateInput {
    create?: InputMaybe<Array<PageCreateInput>>;
    connect?: InputMaybe<Array<PageWhereUniqueInput>>;
}

export interface Page {
    __typename?: "Page";
    id: Scalars["ID"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    license?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
    template?: Maybe<Template>;
    pageType?: Maybe<Scalars["String"]["output"]>;
    content?: Maybe<Scalars["JSON"]["output"]>;
    redirectionCode?: Maybe<Scalars["Int"]["output"]>;
    redirectionUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface PageWhereUniqueInput {
    id?: InputMaybe<Scalars["ID"]["input"]>;
    slug?: InputMaybe<Scalars["String"]["input"]>;
}

export interface PageWhereInput {
    AND?: InputMaybe<Array<PageWhereInput>>;
    OR?: InputMaybe<Array<PageWhereInput>>;
    NOT?: InputMaybe<Array<PageWhereInput>>;
    id?: InputMaybe<IdFilter>;
    createdBy?: InputMaybe<StringFilter>;
    updatedBy?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeNullableFilter>;
    updatedAt?: InputMaybe<DateTimeNullableFilter>;
    license?: InputMaybe<StringFilter>;
    slug?: InputMaybe<StringFilter>;
    title?: InputMaybe<StringFilter>;
    template?: InputMaybe<TemplateWhereInput>;
}

export interface PageOrderByInput {
    id?: InputMaybe<OrderDirection>;
    createdBy?: InputMaybe<OrderDirection>;
    updatedBy?: InputMaybe<OrderDirection>;
    createdAt?: InputMaybe<OrderDirection>;
    updatedAt?: InputMaybe<OrderDirection>;
    license?: InputMaybe<OrderDirection>;
    slug?: InputMaybe<OrderDirection>;
    title?: InputMaybe<OrderDirection>;
}

export interface PageUpdateInput {
    slug?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    template?: InputMaybe<TemplateRelateToOneForUpdateInput>;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
}

export interface TemplateRelateToOneForUpdateInput {
    create?: InputMaybe<TemplateCreateInput>;
    connect?: InputMaybe<TemplateWhereUniqueInput>;
    disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface PageUpdateArgs {
    where: PageWhereUniqueInput;
    data: PageUpdateInput;
}

export interface PageCreateInput {
    slug?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    template?: InputMaybe<TemplateRelateToOneForCreateInput>;
}

export interface TemplateRelateToOneForCreateInput {
    create?: InputMaybe<TemplateCreateInput>;
    connect?: InputMaybe<TemplateWhereUniqueInput>;
}

export interface Form {
    __typename?: "Form";
    id: Scalars["ID"]["output"];
    data?: Maybe<Scalars["JSON"]["output"]>;
}

export interface FormWhereUniqueInput {
    id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface FormWhereInput {
    AND?: InputMaybe<Array<FormWhereInput>>;
    OR?: InputMaybe<Array<FormWhereInput>>;
    NOT?: InputMaybe<Array<FormWhereInput>>;
    id?: InputMaybe<IdFilter>;
}

export interface FormOrderByInput {
    id?: InputMaybe<OrderDirection>;
}

export interface FormUpdateInput {
    data?: InputMaybe<Scalars["JSON"]["input"]>;
}

export interface FormUpdateArgs {
    where: FormWhereUniqueInput;
    data: FormUpdateInput;
}

export interface FormCreateInput {
    data?: InputMaybe<Scalars["JSON"]["input"]>;
}

export interface Submission {
    __typename?: "Submission";
    id: Scalars["ID"]["output"];
    answers?: Maybe<Scalars["JSON"]["output"]>;
    formId?: Maybe<Form>;
}

export interface SubmissionWhereUniqueInput {
    id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface SubmissionWhereInput {
    AND?: InputMaybe<Array<SubmissionWhereInput>>;
    OR?: InputMaybe<Array<SubmissionWhereInput>>;
    NOT?: InputMaybe<Array<SubmissionWhereInput>>;
    id?: InputMaybe<IdFilter>;
    formId?: InputMaybe<FormWhereInput>;
}

export interface SubmissionOrderByInput {
    id?: InputMaybe<OrderDirection>;
}

export interface SubmissionUpdateInput {
    answers?: InputMaybe<Scalars["JSON"]["input"]>;
    formId?: InputMaybe<FormRelateToOneForUpdateInput>;
}

export interface FormRelateToOneForUpdateInput {
    create?: InputMaybe<FormCreateInput>;
    connect?: InputMaybe<FormWhereUniqueInput>;
    disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface SubmissionUpdateArgs {
    where: SubmissionWhereUniqueInput;
    data: SubmissionUpdateInput;
}

export interface SubmissionCreateInput {
    answers?: InputMaybe<Scalars["JSON"]["input"]>;
    formId?: InputMaybe<FormRelateToOneForCreateInput>;
}

export interface FormRelateToOneForCreateInput {
    create?: InputMaybe<FormCreateInput>;
    connect?: InputMaybe<FormWhereUniqueInput>;
}

export interface Mutation {
    __typename?: "Mutation";
    createTemplate?: Maybe<Template>;
    createTemplates?: Maybe<Array<Maybe<Template>>>;
    updateTemplate?: Maybe<Template>;
    updateTemplates?: Maybe<Array<Maybe<Template>>>;
    deleteTemplate?: Maybe<Template>;
    deleteTemplates?: Maybe<Array<Maybe<Template>>>;
    createPage?: Maybe<Page>;
    createPages?: Maybe<Array<Maybe<Page>>>;
    updatePage?: Maybe<Page>;
    updatePages?: Maybe<Array<Maybe<Page>>>;
    deletePage?: Maybe<Page>;
    deletePages?: Maybe<Array<Maybe<Page>>>;
    createForm?: Maybe<Form>;
    createForms?: Maybe<Array<Maybe<Form>>>;
    updateForm?: Maybe<Form>;
    updateForms?: Maybe<Array<Maybe<Form>>>;
    deleteForm?: Maybe<Form>;
    deleteForms?: Maybe<Array<Maybe<Form>>>;
    createSubmission?: Maybe<Submission>;
    createSubmissions?: Maybe<Array<Maybe<Submission>>>;
    updateSubmission?: Maybe<Submission>;
    updateSubmissions?: Maybe<Array<Maybe<Submission>>>;
    deleteSubmission?: Maybe<Submission>;
    deleteSubmissions?: Maybe<Array<Maybe<Submission>>>;
    endSession: Scalars["Boolean"]["output"];
    authFromToken?: Maybe<Scalars["String"]["output"]>;
}

export interface MutationCreateTemplateArgs {
    data: TemplateCreateInput;
}

export interface MutationCreateTemplatesArgs {
    data: Array<TemplateCreateInput>;
}

export interface MutationUpdateTemplateArgs {
    where: TemplateWhereUniqueInput;
    data: TemplateUpdateInput;
}

export interface MutationUpdateTemplatesArgs {
    data: Array<TemplateUpdateArgs>;
}

export interface MutationDeleteTemplateArgs {
    where: TemplateWhereUniqueInput;
}

export interface MutationDeleteTemplatesArgs {
    where: Array<TemplateWhereUniqueInput>;
}

export interface MutationCreatePageArgs {
    data: PageCreateInput;
}

export interface MutationCreatePagesArgs {
    data: Array<PageCreateInput>;
}

export interface MutationUpdatePageArgs {
    where: PageWhereUniqueInput;
    data: PageUpdateInput;
}

export interface MutationUpdatePagesArgs {
    data: Array<PageUpdateArgs>;
}

export interface MutationDeletePageArgs {
    where: PageWhereUniqueInput;
}

export interface MutationDeletePagesArgs {
    where: Array<PageWhereUniqueInput>;
}

export interface MutationCreateFormArgs {
    data: FormCreateInput;
}

export interface MutationCreateFormsArgs {
    data: Array<FormCreateInput>;
}

export interface MutationUpdateFormArgs {
    where: FormWhereUniqueInput;
    data: FormUpdateInput;
}

export interface MutationUpdateFormsArgs {
    data: Array<FormUpdateArgs>;
}

export interface MutationDeleteFormArgs {
    where: FormWhereUniqueInput;
}

export interface MutationDeleteFormsArgs {
    where: Array<FormWhereUniqueInput>;
}

export interface MutationCreateSubmissionArgs {
    data: SubmissionCreateInput;
}

export interface MutationCreateSubmissionsArgs {
    data: Array<SubmissionCreateInput>;
}

export interface MutationUpdateSubmissionArgs {
    where: SubmissionWhereUniqueInput;
    data: SubmissionUpdateInput;
}

export interface MutationUpdateSubmissionsArgs {
    data: Array<SubmissionUpdateArgs>;
}

export interface MutationDeleteSubmissionArgs {
    where: SubmissionWhereUniqueInput;
}

export interface MutationDeleteSubmissionsArgs {
    where: Array<SubmissionWhereUniqueInput>;
}

export interface MutationAuthFromTokenArgs {
    token?: InputMaybe<Scalars["String"]["input"]>;
    licenseId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface Query {
    __typename?: "Query";
    templates?: Maybe<Array<Template>>;
    template?: Maybe<Template>;
    templatesCount?: Maybe<Scalars["Int"]["output"]>;
    pages?: Maybe<Array<Page>>;
    page?: Maybe<Page>;
    pagesCount?: Maybe<Scalars["Int"]["output"]>;
    forms?: Maybe<Array<Form>>;
    form?: Maybe<Form>;
    formsCount?: Maybe<Scalars["Int"]["output"]>;
    submissions?: Maybe<Array<Submission>>;
    submission?: Maybe<Submission>;
    submissionsCount?: Maybe<Scalars["Int"]["output"]>;
    keystone: KeystoneMeta;
}

export interface QueryTemplatesArgs {
    where?: TemplateWhereInput;
    orderBy?: Array<TemplateOrderByInput>;
    take?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: Scalars["Int"]["input"];
    cursor?: InputMaybe<TemplateWhereUniqueInput>;
}

export interface QueryTemplateArgs {
    where: TemplateWhereUniqueInput;
}

export interface QueryTemplatesCountArgs {
    where?: TemplateWhereInput;
}

export interface QueryPagesArgs {
    where?: PageWhereInput;
    orderBy?: Array<PageOrderByInput>;
    take?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: Scalars["Int"]["input"];
    cursor?: InputMaybe<PageWhereUniqueInput>;
}

export interface QueryPageArgs {
    where: PageWhereUniqueInput;
}

export interface QueryPagesCountArgs {
    where?: PageWhereInput;
}

export interface QueryFormsArgs {
    where?: FormWhereInput;
    orderBy?: Array<FormOrderByInput>;
    take?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: Scalars["Int"]["input"];
    cursor?: InputMaybe<FormWhereUniqueInput>;
}

export interface QueryFormArgs {
    where: FormWhereUniqueInput;
}

export interface QueryFormsCountArgs {
    where?: FormWhereInput;
}

export interface QuerySubmissionsArgs {
    where?: SubmissionWhereInput;
    orderBy?: Array<SubmissionOrderByInput>;
    take?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: Scalars["Int"]["input"];
    cursor?: InputMaybe<SubmissionWhereUniqueInput>;
}

export interface QuerySubmissionArgs {
    where: SubmissionWhereUniqueInput;
}

export interface QuerySubmissionsCountArgs {
    where?: SubmissionWhereInput;
}

export interface KeystoneMeta {
    __typename?: "KeystoneMeta";
    adminMeta: KeystoneAdminMeta;
}

export interface KeystoneAdminMeta {
    __typename?: "KeystoneAdminMeta";
    lists: Array<KeystoneAdminUiListMeta>;
    list?: Maybe<KeystoneAdminUiListMeta>;
}

export interface KeystoneAdminMetaListArgs {
    key: Scalars["String"]["input"];
}

export interface KeystoneAdminUiListMeta {
    __typename?: "KeystoneAdminUIListMeta";
    key: Scalars["String"]["output"];
    itemQueryName: Scalars["String"]["output"];
    listQueryName: Scalars["String"]["output"];
    hideCreate: Scalars["Boolean"]["output"];
    hideDelete: Scalars["Boolean"]["output"];
    path: Scalars["String"]["output"];
    label: Scalars["String"]["output"];
    singular: Scalars["String"]["output"];
    plural: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    initialColumns: Array<Scalars["String"]["output"]>;
    pageSize: Scalars["Int"]["output"];
    labelField: Scalars["String"]["output"];
    fields: Array<KeystoneAdminUiFieldMeta>;
    groups: Array<KeystoneAdminUiFieldGroupMeta>;
    initialSort?: Maybe<KeystoneAdminUiSort>;
    isHidden: Scalars["Boolean"]["output"];
    isSingleton: Scalars["Boolean"]["output"];
}

export interface KeystoneAdminUiFieldMeta {
    __typename?: "KeystoneAdminUIFieldMeta";
    path: Scalars["String"]["output"];
    label: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    isOrderable: Scalars["Boolean"]["output"];
    isFilterable: Scalars["Boolean"]["output"];
    isNonNull?: Maybe<Array<KeystoneAdminUiFieldMetaIsNonNull>>;
    fieldMeta?: Maybe<Scalars["JSON"]["output"]>;
    viewsIndex: Scalars["Int"]["output"];
    customViewsIndex?: Maybe<Scalars["Int"]["output"]>;
    createView: KeystoneAdminUiFieldMetaCreateView;
    listView: KeystoneAdminUiFieldMetaListView;
    itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
    search?: Maybe<QueryMode>;
}

export interface KeystoneAdminUiFieldMetaItemViewArgs {
    id?: InputMaybe<Scalars["ID"]["input"]>;
}

export enum KeystoneAdminUiFieldMetaIsNonNull {
    Read = "read",
    Create = "create",
    Update = "update",
}

export interface KeystoneAdminUiFieldMetaCreateView {
    __typename?: "KeystoneAdminUIFieldMetaCreateView";
    fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
}

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
    Edit = "edit",
    Hidden = "hidden",
}

export interface KeystoneAdminUiFieldMetaListView {
    __typename?: "KeystoneAdminUIFieldMetaListView";
    fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
}

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
    Read = "read",
    Hidden = "hidden",
}

export interface KeystoneAdminUiFieldMetaItemView {
    __typename?: "KeystoneAdminUIFieldMetaItemView";
    fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
    fieldPosition?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldPosition>;
}

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
    Edit = "edit",
    Read = "read",
    Hidden = "hidden",
}

export enum KeystoneAdminUiFieldMetaItemViewFieldPosition {
    Form = "form",
    Sidebar = "sidebar",
}

export interface KeystoneAdminUiFieldGroupMeta {
    __typename?: "KeystoneAdminUIFieldGroupMeta";
    label: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    fields: Array<KeystoneAdminUiFieldMeta>;
}

export interface KeystoneAdminUiSort {
    __typename?: "KeystoneAdminUISort";
    field: Scalars["String"]["output"];
    direction: KeystoneAdminUiSortDirection;
}

export enum KeystoneAdminUiSortDirection {
    Asc = "ASC",
    Desc = "DESC",
}
