import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { LocalStorageKeys } from "../../models";
import { getLocalStorageItem } from "../../utils";

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    queryDeduplication: false,
});

export const authMiddlewareLink = new ApolloLink((operation, forward) => {
    const authData = localStorage.getItem(LocalStorageKeys.AUTH) || "{}";
    const selectedLicenseId = getLocalStorageItem(LocalStorageKeys.SELECTED_LICENSE_ID) || '';
    const { token } = JSON.parse(authData);

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${token || ""}`,
            ['x-license-id']: selectedLicenseId,
        },
    }));

    return forward(operation);
});
