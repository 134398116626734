import * as React from "react";
import { globals } from "../globals";
import LoadingOverlay from "../components/LoadingOverlay";
import { createHttpLink } from "apollo-link-http";
import { apolloClient, authMiddlewareLink } from "../api/cms/client";
import { ApolloLink } from "@apollo/client";

export interface AppSettings {
    authUrl: string;
    authRealm: string;
    authClientId: string;
    serverUrl: string;
}

export const AppSettingsContext = React.createContext<AppSettings>(
    {} as AppSettings
);

export const AppSettingsProvider: React.FC<React.PropsWithChildren> = (
    props
) => {
    const [settings, setSettings] = React.useState<AppSettings>();
    const [isError, setIsError] = React.useState<boolean>(false);

    const fetchSettings = () => {
        fetch("/settings.json")
            .then((response) => response.json())
            .then((data) => {
                setSettings(data);
                globals.settings = data;

                const link = createHttpLink({
                    uri: globals.settings.cmsGraphQlUrl,
                    credentials: "include",
                });

                apolloClient.setLink(authMiddlewareLink.concat(link as unknown as ApolloLink));
            })
            .catch((error) => {
                console.error("Error: can not fetch settings.json", error);
                setIsError(true);
            });
    };

    React.useEffect(() => {
        fetchSettings();
    }, []);

    if (isError) {
        return <div>Error: can not fetch settings.json</div>;
    }

    const loader = (
        <LoadingOverlay
            label="Loading"
            containerClassName="fobi-loading-overlay"
        />
    );

    if (!settings) {
        return loader;
    }

    return (
        <AppSettingsContext.Provider value={settings}>
            {props.children}
        </AppSettingsContext.Provider>
    );
};
