import keycloak from "../Keycloak";
import { KeycloakData } from "../models/api/Auth/login";
import { IGetLicenseByLicenseIdResponse } from "../models/api/License/getLicenseByLicenseId";
import imageCompression from "browser-image-compression";
import { ITableSortOptions } from "@loopinsights/fobi-common-ui";
import { LocalStorageKeys } from "../models";
import jwtDecode from "jwt-decode";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { JSONSchema7, JSONSchema7TypeName } from "json-schema";

export const validateEmailRegex = (email: string) => {
    return email.toLowerCase().match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const RegexPhoneNumber = /^\+\d{1,4}\s?\-?\s?\d{6,14}$/gm;
export const validatePhoneNumber = (phoneNumber: string) => {
    try {
        const ph = parsePhoneNumber(phoneNumber);
        const valid = isValidPhoneNumber(ph.format("E.164"));
        return valid ? /^\+[1-9]\d{10,14}$/g.test(ph.format("E.164")) : false;
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const compareObjects = (a: any, b: any) => {
    if (!a || !b) {
        return false;
    }
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
        return false;
    }
    for (let i = 0; i < aKeys.length; i++) {
        if (aKeys[i] !== bKeys[i]) {
            return false;
        }
        if (a[aKeys[i]] !== b[bKeys[i]]) {
            return false;
        }
    }
    return true;
};
export const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getKeycloakDataPayload = (kc: typeof keycloak) => {
    const payload: KeycloakData = {
        authenticated: kc.authenticated,
        token: kc.token,
        refreshToken: kc.refreshToken,
        idToken: kc.idToken,
        timeSkew: kc.timeSkew,
    };
    return payload;
};

export const ensureRefreshKeycloakToken = async (kc: typeof keycloak) => {
    const isRefreshTokenValid =
        new Date().getTime() <
        new Date((kc.refreshTokenParsed?.exp || 0) * 1000).getTime();
    if (
        kc.authenticated &&
        isRefreshTokenValid &&
        kc.authenticated &&
        kc.isTokenExpired()
    ) {
        await kc.updateToken(30);
        return true;
    }
    return false;
};

export const getLicenseOwnerName = (
    license: IGetLicenseByLicenseIdResponse | undefined
) => {
    if (!license) {
        return "";
    }

    const licenseUsers = license.licenseUsers;
    let licenseOwnerUser;

    for (let i = 0; i < licenseUsers.length; i++) {
        const user = licenseUsers[i].user;
        const roles = licenseUsers[i].roles;

        for (let j = 0; j < roles.length; j++) {
            const role = roles[j];
            if (role.name === "License Owner") {
                licenseOwnerUser = user;
                break;
            }
        }

        if (licenseOwnerUser) {
            break;
        }
    }
    return `${licenseOwnerUser?.firstName} ${licenseOwnerUser?.lastName}`;
};

export const getCompressedBase64 = async (file: File) => {
    const fileCompressionOptions = {
        alwaysKeepResolution: false,
        maxWidthOrHeight: 200,
        maxSizeMB: 0.05,
    };
    const compressedFile = await imageCompression(file, fileCompressionOptions);
    return (await getBase64(compressedFile)) as string;
};
export interface IPaginateQuery {
    [key: string]: string | string[];
}
export interface IPaginateParams {
    page?: number;
    limit?: number;
    sort?: ITableSortOptions;
    query?: IPaginateQuery[];
    otherQueryParams?: Record<string, any>;
    aggs?: string;
}

export const paginateAppendURL = ({
    limit,
    page,
    query = [],
    sort,
    url,
    ...otherQueryParams
}: IPaginateParams & { url: string }): string => {
    const queries = <string[]>[];
    const pushQuery = (query: string) => {
        const symbol = queries.length > 0 ? "&" : "?";
        queries.push(`${symbol}${query}`);
    };
    if (limit) {
        pushQuery(`limit=${limit}`);
    }
    if (page) {
        pushQuery(`page=${page}`);
    }
    if (sort?.key) {
        const sortQuery = `{"${sort["key"]}":"${sort["direction"]}"}`;
        pushQuery(`sortBy=${sortQuery}`);
    }
    if (query.length > 0) {
        pushQuery(`query=${JSON.stringify(query)}`);
    }

    // Add otherQueryParams handling
    const otherParams = otherQueryParams as Record<string, any>;
    for (const key in otherParams) {
        if (Object.prototype.hasOwnProperty.call(otherParams, key)) {
            const value = otherParams[key];
            pushQuery(`${key}=${value}`);
        }
    }

    const returnUrl = `${url}${queries.join("")}`;
    return returnUrl;
};

export function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function mergeJsonArrayToObject(jsonArray: any[]): any {
    const mergedObject: any = {};
    if (!jsonArray) {
        return mergedObject;
    }
    jsonArray.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
            mergedObject[key] = item[key];
        });
    });

    return mergedObject;
}

export const preventScrollOnNumberInput = (
    inputRef: React.RefObject<HTMLInputElement>
) => {
    const inputElement = inputRef.current;
    if (inputElement?.focus) {
        const handleWheel = (event: WheelEvent) => {
            event.preventDefault();
        };
        inputElement.addEventListener("wheel", handleWheel, { passive: false });
        return () => {
            inputElement.removeEventListener("wheel", handleWheel);
        };
    }
};

export const compareArrays = (
    array1: string[] | undefined,
    array2: string[] | undefined
): boolean | null => {
    return JSON.stringify(array1?.sort()) === JSON.stringify(array2?.sort());
};

const getUserId = () => {
    const auth = localStorage.getItem(LocalStorageKeys.AUTH);
    if (auth) {
        const decoded = jwtDecode(JSON.parse(auth).token) as { sub?: string };
        return decoded?.sub;
    }
};

export const setLocalStorageItem = (key: string, value: string) => {
    const id = getUserId();
    id && localStorage.setItem(`${id}_${key}`, value);
};

export const getLocalStorageItem = (key: string) => {
    const id = getUserId();
    if (!id) {
        return;
    }
    return localStorage.getItem(`${id}_${key}`);
};

export const removeLocalStorageItem = (key: string) => {
    const id = getUserId();
    id && localStorage.removeItem(`${id}_${key}`);
};

export const saveLicenseRegisterIntoLocalStorage = (
    key: string,
    details: any
) => {
    const licenseRegistration = getLocalStorageItem(
        LocalStorageKeys.LICENSE_REGISTRATION
    );
    const parsedLicenseRegistration = licenseRegistration
        ? JSON.parse(licenseRegistration)
        : {};
    parsedLicenseRegistration[key] = details;
    setLocalStorageItem(
        LocalStorageKeys.LICENSE_REGISTRATION,
        JSON.stringify(parsedLicenseRegistration)
    );
};

export const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
};

export const getMimeTypeFromExtension = (filename: string) => {
    const parts = filename.split(".");
    const extension = parts.length > 0 ? parts.pop()?.toLowerCase() : "";
    switch (extension) {
        case "jpg":
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        default:
            return "image/jpeg";
    }
};

export function dataURIToBlob(dataURI: string): Blob {
    const splitDataURI = dataURI.split(",");
    const data = splitDataURI[1];
    const binaryData = atob(data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeString });
}
export const getArrayDifference = (initial: any[], final: any[]): any[] => {
    return final.filter((item) => !initial.includes(item));
};

export const isValidBase64Image = (base64String: string): boolean => {
    if (!base64String.startsWith("data:image")) {
        return false;
    }

    const parts = base64String.split(",");
    if (parts.length !== 2) {
        return false;
    }

    const imageType = parts[0];

    if (!imageType.match(/^data:image\/(png|jpeg|jpg);base64$/)) {
        return false;
    }

    return true;
};

export const renderStatusInTable = (status: any) => {
    if (status) {
        if (status === "inactive") {
            return "pending";
        } else {
            return status;
        }
    } else {
        return "active";
    }
};

export const validUrlPattern =
    /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,6})(\/[\w.-]*)*\/?$/;

export const generateAjvSchema = (sampleObject: any): JSONSchema7 => {
    const schema: JSONSchema7 = {
        type: "object",
        properties: {},
        required: [],
    };

    for (const key in sampleObject) {
        if (sampleObject.hasOwnProperty(key) && schema.properties) {
            const propertyValue = sampleObject[key];
            const propertyType: JSONSchema7TypeName | "bigint" =
                typeof propertyValue as JSONSchema7TypeName | "bigint";

            if (propertyType !== "bigint") {
                if (propertyValue === null) {
                    schema.properties[key] = {
                        type: "null", // Set type to "null" for null values
                    };
                } else if (Array.isArray(propertyValue)) {
                    if (propertyValue.length > 0) {
                        const arrayItemType: JSONSchema7TypeName =
                            typeof propertyValue[0] as JSONSchema7TypeName;
                        schema.properties[key] = {
                            type: "array",
                            items: {
                                type: arrayItemType,
                            },
                        };

                        if (arrayItemType === "object") {
                            (schema.properties[key] as JSONSchema7).items =
                                generateAjvSchema(
                                    propertyValue[0]
                                ) as JSONSchema7;
                        }

                        // Check if the array has at least 1 element
                        if (propertyValue.length >= 1) {
                            schema.required = schema.required || [];
                            schema.required.push(key);
                        }
                    }
                } else if (propertyType === "object") {
                    schema.properties[key] = generateAjvSchema(propertyValue);
                } else {
                    schema.properties[key] = {
                        type: propertyType,
                    };
                }
            }
        }
    }

    return schema;
};

export const isValidDate = (dateString: string) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
};

export const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    // Create the formatted date and time string
    const formattedTime = `${year}_${month}_${day}-${hours}_${minutes}_${seconds}`;
    return formattedTime;
};

export const isJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateJsonPathExpression = (
    data: any,
    targetValue: any,
    currentPath: string = "$"
): string | null => {
    for (const key in data) {
        if (data[key] === targetValue) {
            return `${currentPath}.${key}`;
        }

        if (typeof data[key] === "object") {
            const result = generateJsonPathExpression(
                data[key],
                targetValue,
                `${currentPath}.${key}`
            );
            if (result) {
                return result;
            }
        }
    }

    return null;
};
