import { globals } from "../../../globals";
const hostUrl = globals.settings.ciUrl;

export const FOBI_SUPPORT = `https://about.${hostUrl}`;

export const FOBI_FEEDBACK = `mailto:support@${hostUrl}`;

export const linkTagStyleRevert = {
    color: 'inherit',
    textDecoration: 'none',
};
