import React from "react";
import { Spinner } from "@loopinsights/fobi-common-ui";
import "./LoadingOverlay.scss";

interface ILoadingOverlayProps {
    label: string;
    containerClassName?: string;
}

const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({
    containerClassName,
    label,
}: ILoadingOverlayProps) => {
    return (
        <div
            className={`fobi-login__login-modal__loading-overlay-wrapper fobi-text-capitalize ${
                containerClassName || ""
            }`}
        >
            <Spinner className="fobi-loading-spinner" size="lg" label={label} />
        </div>
    );
};

export default LoadingOverlay;
