import { useEffect, useState } from "react";
import { IPaginateQuery } from "../utils";
import { useSearchParams } from "react-router-dom";
import { IUsePagination } from "./usePagination";
import { SelectOptionsV2 } from "@loopinsights/fobi-common-ui";

export interface IFilterOptions {
    [key: string]: {
        label: string;
        value: string;
        type?: 'input' | 'datepicker' | 'select';
        options?: SelectOptionsV2[];
        filterMode?: 'contains' | 'equals';
    }
}
export interface IUseFilterProps {
    setCurrentPage?: (currentPage: IUsePagination['page']) => void;
    defaultFilter?: IFilterOptions;
}

// query = [{ 'firstName': 'John' }, { 'lastName': 'Doe' }];
// options =
// {
//     'firstName': {
//         label: 'First Name',
//         value: 'John'
//     },
//     'lastName': {
//         label: 'Last Name',
//         value: 'Doe'
//     },
// }

export function useFilter({
    defaultFilter={}, setCurrentPage
}: IUseFilterProps = {}) {
    const [options, setOptions] = useState<IFilterOptions>(defaultFilter);
    const [query, setQuery] = useState<IPaginateQuery[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isInitialRender, setIsInitialRender] = useState(true);

    const applyFilter = () => {
        const newQuery: IPaginateQuery[] = [];
        Object.keys(options).forEach((column: string) => {
            if (options[column].value) {
                newQuery.push({
                    [column]: options[column].value
                });
            }
        });
        setQuery(newQuery);

        setSearchParams((params) => {
            for (const key in options) {
                if (options[key].value) {
                    params.set(key, options[key].value);
                }
                else {
                    params.delete(key);
                }
            }
            return params;
        });
                    
    };
    const resetFilter = () => {
        setOptions(defaultFilter);
        setQuery([]);
        setSearchParams((params) => {
            for (const key in options) {
                params.delete(key);
            }
            return params;
        });
    };

    useEffect(() => {
        // Function to update filter options based on query parameters
        const updateFilterOptionsFromQuery = () => {
            const updatedOptions = { ...options };
    
            for (const key in options) {
                const queryValue =searchParams.get(key);
                if (queryValue !== null) {
                    updatedOptions[key].value = queryValue;
                }
                else{
                    updatedOptions[key].value = "";
                }
            }
            setOptions(updatedOptions);
        };
    
        updateFilterOptionsFromQuery();
        applyFilter();
    }, []);

    useEffect(() => {
        if(!isInitialRender && query.length!==0){
            setCurrentPage?.(1);
        }
        setIsInitialRender(false);
    }, [query]);

    useEffect(()=>{
        const hasSearchParams = searchParams.toString() !== "";
        if(!hasSearchParams){
            const updatedOptions = { ...options };
            for (const key in options) {
                const queryValue =searchParams.get(key);
                if (queryValue !== null) {
                    updatedOptions[key].value = queryValue;
                }
                else{
                    updatedOptions[key].value = "";
                }
            }
            setOptions(updatedOptions);
            applyFilter();
        }
    }, [searchParams]);


    return {
        options,
        setOptions,
        applyFilter,
        resetFilter,
        query,
    };
}
