import { ITableSortOptions } from "@loopinsights/fobi-common-ui";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderDirection } from "../../api/cms/types";

export interface IUsePagination {
    limit: number;
    page: number;
    sortBy: ITableSortOptions;
}
export interface IUsePaginationProps {
    page?: IUsePagination["page"];
    initialSortBy?: IUsePagination["sortBy"];
    limit?: IUsePagination["limit"];
    initialItemsPerPage?: number;
}

export function usePagination({
    initialItemsPerPage = 10,
    initialSortBy = { key: "createdAt", direction: "desc" },
    limit = 10,
    page = 1,
}: IUsePaginationProps = {}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const customItemsPerPage = Number(searchParams.get("itemsPerPage"));
    const customPageNumber = Number(searchParams.get("page"));
    const customSort = searchParams.get("sort");
    let customSortKey = initialSortBy.key;
    let customSortValue = OrderDirection.Desc;

    if (customSort) {
        const parts = customSort.split("_");
        customSortKey = parts[0] || "createdAt";
        customSortValue = (parts[1] as OrderDirection) || OrderDirection.Desc;
    }
    const [currentPage, setCurrentPage] = useState<IUsePagination["page"]>(
        customPageNumber !== 0 ? customPageNumber : page
    );
    const [totalPages, setTotalPages] = useState(limit);
    const [totalItems, setTotalItems] = useState(0);
    const [sort, setSort] = useState<ITableSortOptions>({
        key: customSortKey,
        direction: customSortValue,
    });
    const [itemsPerPage, setItemsPerPage] = useState(
        customItemsPerPage || initialItemsPerPage
    );
    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        setIsInitialRender(false);
        if (!isInitialRender) {
            setCurrentPage(1);
        }
    }, [itemsPerPage]);

    useEffect(() => {
        const hasSearchParams = searchParams.toString() !== "";
        if (!isInitialRender && !hasSearchParams) {
            setCurrentPage(page);
            setSort(initialSortBy);
            setItemsPerPage(initialItemsPerPage);
        }
        setIsInitialRender(false);
    }, [searchParams]);

    useEffect(() => {
        if (
            sort.key === initialSortBy.key &&
            sort.direction === OrderDirection.Desc
        ) {
            setSearchParams((params) => {
                params.delete("sort");
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.set("sort", `${sort.key}_${sort.direction}`);
                return params;
            });
        }
    }, [sort]);

    useEffect(() => {
        setSearchParams((params) => {
            params.delete("page");
            return params;
        });
        if (itemsPerPage === 10) {
            setSearchParams((params) => {
                params.delete("itemsPerPage");
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.set("itemsPerPage", itemsPerPage.toString());
                return params;
            });
        }
    }, [itemsPerPage]);
    useEffect(() => {
        if (currentPage === 1) {
            setSearchParams((params) => {
                params.delete("page");
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.set("page", currentPage.toString());
                return params;
            });
        }
    }, [currentPage]);

    return {
        currentPage,
        totalPages,
        sort,
        setCurrentPage,
        setTotalPages,
        setSort,
        itemsPerPage,
        setItemsPerPage,
        totalItems,
        setTotalItems,
    };
}

export const TableDefaultSort: IUsePagination["sortBy"] = {
    key: "createdAt",
    direction: "desc",
};
export const AuditTableDefaultSort: IUsePagination["sortBy"] = {
    key: "audit.timestamp",
    direction: "desc",
};
export const ProductTableDefaultSort: IUsePagination["sortBy"] = {
    key: "name",
    direction: "asc",
};
export const SchedulerHistoryTableDefaultSort: IUsePagination["sortBy"] = {
    key: "executeAt",
    direction: "desc",
};
